import image from '../../images/blog/adherence-crisis.png'

export default {
  url: '/blog/adherence-crisis',
  title: `The Adherence Crisis in Clinical Trials`,
  author: `AdhereTech`,
  publishDate: `2024-04-11`,
  image,
  body: `
    <h3>Overview:</h3>
    <ul>
      <li>
        Poor medication adherence is silently undermining clinical trials, compromising the development of life-saving treatments and increasing the risk of trial failure.
      </li>
      <li>
        Traditional adherence tracking methods are woefully inadequate, greatly overestimating compliance and obscuring the true extent of the problem.
      </li>
      <li>
        In response, the FDA has called for innovative solutions, specifically highlighting the use of smart pill bottles and alert systems to ensure adherence.
      </li>
      <li>
        By embracing these technologies, investigators can increase medication exposure, reduce study variability, and ensure novel medications are demonstrating their potential to help patients.
      </li>
    </ul>
    <h3>The Adherence Crisis:</h3>
    <ul>
      <li>
        An analysis of 95 clinical trials across 28 therapeutic areas revealed that <strong>half of the 16,907 participants exhibited substantial deviations</strong> from prescribed dosing regimens. This analysis included populations of highly motivated patients such as transplant recipients, and patients with HIV, breast cancer, and heart failure. Deviations included long interruptions, taking doses too closely together, and complete discontinuations.[1]
      </li>
      <li>
        Nonadherence is widespread in clinical trials, and consistent with real world studies, with <strong>only 50% adhering at optimal therapeutic levels</strong> in chronic conditions like diabetes and heart disease, leading to 2x higher mortality rates.[2,3,4,5]
      </li>
      <li>
        The situation is even more alarming in minority populations with <strong>nonadherence rates 47% and 44% higher among Black and Hispanic patients.</strong>[6]
      </li>
    </ul>
    <h3>Limitations of Traditional Tracking:</h3>
    <ul>
      <li>
        <strong>Pill counting and patient surveys are woefully inaccurate and overestimate adherence.</strong>[7,8,9,10] One study showed the accuracy of pill count is just 60% and self report just 27%.[8] Patients often return empty packages to avoid retraining or exclusion, skewing data. Another study that evaluated the accuracy of pill count concluded it <strong>“grossly overestimates adherence”</strong> and <strong>“its continued use in clinical trials cannot be justified."</strong>[7]
      </li>
      <li>
        Patient apps and video recording have also proven inadequate, being <strong>prone to bias and plagued with technical problems</strong>, such as upload issues, camera complications, and patients forgetting to self-record.[11]
      </li>
      <li>
        Older smart pill bottles/caps also fall short, requiring local data transfer to apps <strong>adding to patient burden, lack real-time tracking, and only work to capture adherence, not improve it.</strong>
      </li>
    </ul>
    <h3>Severe Consequences:</h3>
    <ul>
      <li>
        The MOUNTAIN study, a Phase III trial investigating Zuranolone in patients with major depressive disorder (MDD), failed to show significant separation between the intervention and control group despite reporting a 98% adherence rate.[12] However, <strong>a post hoc analysis revealed that 9% of patients had no detectable drug concentration due to nonadherence. When these patients were excluded, a notable difference in clinical outcomes emerged.</strong>[13] After FDA rejected Zuranolone for the treatment of MDD, with projected peak annual sales of $1.3 Billion, Sage Therapeutics revealed its plan to lay off 40% of its workforce and its market cap plummeted by over $8 Billion.[14,28]
      </li>
      <li>
        Spruce Biosciences reported disappointing topline results from its CAHmelia-203 study of tildacerfont in Adult Classic Congenital Adrenal Hyperplasia (CAH), with the clinical trial failing to achieve its primary efficacy endpoint. <strong>The organization pointed to nonadherence as the cause of the trial failure, “resulting in lower than-expected tildacerfont exposure.”</strong> [15] Consequently, Spruce is terminating the trial, experienced a drop in its stock price by over 80%, and is laying off 21% of its workforce to extend its cash runway.[16,17,18,28]
      </li>
      <li>
        Older smart pill bottles/caps also fall short, requiring local data transfer to apps <strong>adding to patient burden, lack real-time tracking, and only work to capture adherence, not improve it.</strong>
      </li>
    </ul>
    <blockquote>
      <em>
        <strong>
          Trials that fail to show effectiveness because of nonadherence may be a widespread issue, and this suggestion is supported by the published evidence. However, because adherence has not been measured and reported appropriately in trial settings, and because there is a tendency towards under-publication of failed  trials, the data are obscured and we cannot truly know how many compounds have been affected by this problem throughout the history of drug development.
        </strong>
      </em>
    </blockquote>
    <p>– Authors of the EMERGE Guidelines[22]</p>
    <h3>FDA's Call for Action:</h3>
    <ul>
      <li>
        Recognizing the importance of ensuring adherence, FDA published <em>“Enrichment Strategies for Clinical Trials to Support Determination of Effectiveness of Human Drugs and Biological Products”</em> in 2019. 
      </li>
      <li>
        FDA emphasizes the need for solutions, <strong>specifically calling out the use of  adherence prompts, smart pill bottles and alert systems.</strong> They say the purpose of these strategies “are to increase the efficiency of drug development and support precision medicine” and assist industry in “demonstrating the effectiveness of drug and biological products.”[23] FDA’s guidance underscores the importance of leveraging the appropriate technology to enhance patient adherence and improve patient care.
      </li>
    </ul>
    <h3>The Solution: Smart Pill Devices</h3>
    <p>
      Sponsors and investigators are increasingly adopting AdhereTech's Patented Aidia System (bottle or cap), to address the adherence crisis:
    </p>
    <p>
      <strong>✓</strong> The Aidia System offers <strong>real-time, accurate tracking</strong> without traditional methods' limitations and uses habit-forming reinforcement partnering with each patient to <strong>significantly improve adherence.</strong>
    </p>
    <p>
      <strong>✓</strong> With <strong>no patient setup, no app, and cellular data capture</strong>, Aidia minimizes patient burden and consistently <strong>delivers >95% patient satisfaction.</strong> It can be easily and quickly implemented having been successfully used in over 50 clinical trials. 
    </p>
    <p>
      <strong>✓</strong> Aidia's robust platform leverages machine learning and a methodology <strong>rooted in behavioral science and habit formation.</strong> Its optimal cadence of alerts and positive reinforcement messaging helps patients stay on track with the protocol and solidify habits, <strong>improving adherence as much as 22% and demonstrating higher drug concentration.</strong>[24,25,26,27]
    </p>
    <p>
      <strong>✓</strong> Aidia has also been <strong>shown to increase patient retention by at least 77% in real world studies</strong> involving more than 28,000 patients across various products and disease areas. The system is FDA Class 1, CE marked, and manufactured to GMP and ISO 13485 standards, ensuring the highest quality and reliability. 
    </p>
    <h3>Conclusion:</h3>
    <p>
      The adherence crisis silently threatens clinical trial validity and represents a significant, known risk of study failure that can be greatly mitigated. Traditional tracking methods are inadequate, obscuring the true problem and compromising study outcomes. As the FDA emphasizes the importance of ensuring adherence in clinical trials, sponsors and investigators must act to address this crisis. Smart Pill Devices like Aidia provide a comprehensive solution to significantly improve patient adherence, reduce study variability, and help ensure novel medications demonstrate their potential to help patients.
    </p>
    <hr/>
      For more information about AdhereTech’s Aidia System, email: <a href="mailto:info@adheretech.com">info@adheretech.com</a> or visit <a href="https://www.adheretech.com">www.adheretech.com</a>
    <hr/>
  `,
  references: [
    `Blaschke TF, Osterberg L, Vrijens B, Urquhart J. Adherence to Medications: Insights Arising from Studies on the Unreliable Link Between Prescribed and Actual Drug Dosing Histories. Annual Review of Pharmacology and Toxicology. 2012;52(1):275-301. doi: https://doi.org/10.1146/annurev-pharmtox-011711-113247`,
    `Robin DiMatteo M, Giordani PJ, Lepper HS, Croghan TW. Patient Adherence and Medical Treatment Outcomes. Medical Care. 2002;40(9):794-811. doi: https://doi.org/10.1097/00005650-200209000-00009`,
    `Hichborn J, Kaganoff S, Subramanian N, Yaar Z. Using data to improve patient adherence | McKinsey. www.mckinsey.com. Published December 14, 2018. https://www.mckinsey.com/industries/life-sciences/our-insights/improving-patient-adherence-through-data-driven-insights`,
    `Hansen LA. Impact of Nonadherence to Cancer Therapy. The Oncology Nurse. Published 2017. https://www.theoncologynurse.com/online-first/3639-ton-3639`,
    `Medication nonadherence: Medicine’s weakest link. www.wolterskluwer.com. Accessed April 4, 2024. https://www.wolterskluwer.com/en/expert-insights/medication-nonadherence-medicines-weakest-link`,
    `Addressing Disparities in Medication Access and Adherence. phrma.org. Published May 1, 2022. https://phrma.org/en/Equity/Addressing-Disparities-in-Medication-Access-and-Adherence`,
    `Pullar T, Kumar S, Tindall H, Feely M. Time to stop counting the tablets? Clinical Pharmacology and Therapeutics. 1989;46(2):163-168. doi: https://doi.org/10.1038/clpt.1989.121`,
    `El Alili M, Vrijens B, Demonceau J, Evers SM, Hiligsmann M. A scoping review of studies comparing the medication event monitoring system (MEMS) with alternative methods for measuring medication adherence. British Journal of Clinical Pharmacology. 2016;82(1):268-279. doi: https://doi.org/10.1111/bcp.12942`,
    `Cramer JA, Mattson RH, Prevey ML, Scheyer RD, Ouellette VL. How often is medication taken as prescribed? A novel assessment technique. JAMA. 1989;261(22):3273-3277. https://pubmed.ncbi.nlm.nih.gov/2716163/`,
    `Bangsberg DR, Hecht FM, Charlebois ED, et al. Adherence to protease inhibitors, HIV-1 viral load, and development of drug resistance in an indigent population. AIDS. 2000;14(4):357-366. doi: https://doi.org/10.1097/00002030-200003100-00008`,
    `Mason M, Cho Y, Rayo J, Gong Y, Harris M, Jiang Y. Technologies for Medication Adherence Monitoring and Technology Assessment Criteria: Narrative Review. JMIR mHealth and uHealth. 2022;10(3):e35157. doi: https://doi.org/10.2196/35157`,
    `Clayton AH, Lasser R, Nandy I, Sankoh AJ, Jonas J, Kanes SJ. Zuranolone in Major Depressive Disorder. The Journal of Clinical Psychiatry. 2023;84(2). doi: https://doi.org/10.4088/jcp.22m14445`,
    `Qiu Y, Tao Y, Duan A, et al. Efficacy and tolerability of zuranolone in patients with depression: a meta-analysis of randomized controlled trials. Frontiers in Pharmacology. 2024;14. doi: https://doi.org/10.3389/fphar.2023.1334694`,
    `Dunleavy K. In downsizing mode, Sage will move headquarters to a smaller space. Fiercepharma.com. Published January 25, 2024. Accessed April 4, 2024. https://www.fiercepharma.com/pharma/downsizing-mode-sage-will-move-headquarters-smaller-space`,
    `Spruce Biosciences Announces Topline Results from CAHmelia-203 in Adult Classic CAH and CAHptain-205 in Pediatric Classic CAH. www.businesswire.com. Published March 13, 2024. Accessed April 7, 2024. https://www.businesswire.com/news/home/20240313147623/en/Spruce-Biosciences-Announces-Topline-Results-from-CAHmelia 203-in-Adult-Classic-CAH-and-CAHptain-205-in-Pediatric-Classic-CAH`,
    `Spruce Biosciences Announces Topline Results from CAHmelia-203 in Adult Classic CAH and CAHptain-205 in Pediatric Classic CAH. BioSpace. Published March 12, 2024. Accessed April 7, 2024. https://www.biospace.com/article/releases/spruce-biosciences-announces-topline-results-from-cahmelia-203-in-adult-classic-cah-and-cahptain-205-in-pediatric-classic-cah/`,
    `news/author/thefly. Spruce Biosciences says CAHmelia-203 study did not meet efficacy endpoint - TipRanks.com. TipRanks Financial. Published March 13, 2024. Accessed April 7, 2024. https://www.tipranks.com/news/the-fly/spruce-biosciences-says-cahmelia-203-study-did-not-meet-efficacy-endpoint`,
    `Liu A. Amylyx to pull failed ALS drug Relyvrio from market, cut 70% of staffers. fiercepharma.com. Published April 4, 2024. Accessed April 6, 2024. https://www.fiercepharma.com/pharma/amylyx-pull-failed-als-drug-relyvrio-market-cut-70-staffers`,
    `Waldron J. Spruce’s stock crashes as biotech lays off 5th of staff in wake of phase 2 hyperplasia fail. Fierce Biotech. Published March 14, 2024. Accessed April 6, 2024. https://www.fiercebiotech.com/biotech/spruces-stock-crashes-biotech-lays-fifth-staff-wake-phase-2-hyperplasia-fail`,
    `Paganoni S, Macklin EA, Hendrix S, et al. Trial of Sodium Phenylbutyrate–Taurursodiol for Amyotrophic Lateral Sclerosis. New England Journal of Medicine. 2020;383(10):919-930. doi: https://doi.org/10.1056/nejmoa1916945`,
    `Kramer N. Recording of virtual meeting – Adherence and retention in ALS clinical trials. www.tricals.org. Published March 22, 2023. Accessed April 7, 2024. https://www.tricals.org/en/news/recording-of-virtual-meeting-adherence-and-retention-in-als-clinical-trials/`,
    `Eliasson L, Clifford S, Mulick A, Jackson C, Vrijens B. How the EMERGE guideline on medication adherence can improve the quality of clinical trials. British Journal of Clinical Pharmacology. 2020;86(4):687-697. doi: https://doi.org/10.1111/bcp.14240`,
    `Center for Drug Evaluation and Research. Enrichment Strategies for Clinical Trials. U.S. Food and Drug Administration. Published 2019. https://www.fda.gov/regulatory-information/search-fda-guidance-documents/enrichment-strategies-clinical-trials-support-approval-human-drugs-and-biological-products`,
    `Newman-Casey PA, Niziol LM, Lee PP, Musch DC, Resnicow K, Heisler M. The Impact of the Support, Educate, Empower Personalized Glaucoma Coaching Pilot Study on Glaucoma Medication Adherence. Ophthalmology Glaucoma. 2020;3(4):228-237. doi: https://doi.org/10.1016/j.ogla.2020.04.013`,
    `Mauro J, Mathews KB, Sredzinski ES. Effect of a Smart Pill Bottle and Pharmacist Intervention on Medication Adherence in Patients with Multiple Myeloma New to Lenalidomide Therapy. Journal of Managed Care & Specialty Pharmacy. 2019;25(11):1244-1254.  doi: https://doi.org/10.18553/jmcp.2019.25.11.1244`,
    `Ellsworth GB, Burke LA, Wells MT, et al. Randomized Pilot Study of an Advanced Smart-Pill Bottle as an Adherence Intervention in Patients With HIV on Antiretroviral Treatment. JAIDS Journal of Acquired Immune Deficiency Syndromes. 2021; 86(1):73 80. doi: https://doi.org/10.1097/qai.0000000000002519`,
    `Siddiqui S, Wenzel SE, Bozik ME, et al. Safety and Efficacy of Dexpramipexole in Eosinophilic Asthma (EXHALE): A randomized controlled trial. The Journal of Allergy and Clinical Immunology. 2023; 152(5):1121 1130.e10. doi: https://doi.org/10.1016/j.jaci.2023.05.014`,
    `Check-Cap Market Cap Insights | YCharts. ycharts.com. Accessed April 9, 2024. https://ycharts.com/companies/CHEK/market_cap`,
  ],
}
